import React from 'react';
import {useStyles} from './styles';

function MenuSelection(props) {
  const classes = useStyles();
  return (
    <div
      className={`${classes.root} ${props.active ? classes.activeRoot : ''}`}
      onClick={props.handleClick}
    >
      {props.active && (
        <div style={{position: 'absolute', left: -23, top: 0}}>
          <div className={classes.diamondArrow}></div>
        </div>
      )}
      <div
        className={classes.flexBox}
        style={{marginLeft: props.title ? 28 : props.active ? 28 : 16}}
      >
        {props.children && (
          <div className={classes.labelIcon}>{props.children}</div>
        )}
        <div>{props.label}</div>
      </div>
    </div>
  );
}
export default React.memo(MenuSelection);
