import axios from "axios";
import { push } from "connected-react-router";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import {toast} from 'react-toastify'

export const setLoginError = (err) => ({
  type: actionTypes.SET_USER_LOGIN_ERROR,
  payload: err,
});

export const userLogin = (user, pwd, redirectURL) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.USER_LOGIN_LOADING, payload: true });
    setTimeout(() => {
      dispatch({ type: actionTypes.USER_LOGIN_LOADING, payload: false });
    }, 5000);
    axios
      .post(`${API_URL}/user/sign-in`, {
        username: user,
        password: pwd,
      })
      .then(async (res) => {
        await window.localStorage.setItem(
          "user_auth_token",
          res.data.token
        );
        await window.localStorage.setItem(
          "user_refresh_token",
          res.data.refreshToken
        );
        await dispatch({
          type: actionTypes.SET_USER_LOGIN_DATA,
          payload: res.data,
        });
        await dispatch({
          type: actionTypes.USER_LOGIN_LOADING,
          payload: false,
        });
        await dispatch(push(redirectURL));
      })
      .catch(() => {
        toast.error('Username/Password is wrong')
        dispatch({ type: actionTypes.USER_LOGIN_LOADING, payload: false });
        dispatch(setLoginError(true));
      });
  };
};

export const userLogout = () => {
  return async (dispatch) => {
    window.localStorage.removeItem("user_auth_token");
    window.localStorage.removeItem("user_refresh_token");
    await dispatch({ type: actionTypes.SET_USER_LOGOUT });
  };
};
