import React from 'react';

function DashboardIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <circle cx="454.393" cy="415.998" r="20" fill="#07cc66" />
      <circle cx="94.393" cy="80.998" r="30" fill="#07cc66" />
      <rect
        width="30"
        height="136.333"
        x="235.393"
        y="309.665"
        fill="#a85120"
      />
      <path
        fill="#c86d36"
        d="M339.533,281.859c3.62,3.62,5.86,8.62,5.86,14.14c0,11.05-8.95,20-20,20h-60h-30h-60 c-5.52,0-10.52-2.24-14.14-5.86c-3.62-3.62-5.86-8.62-5.86-14.14c0-11.05,8.95-20,20-20h150 C330.913,275.998,335.913,278.238,339.533,281.859z"
      />
      <path
        fill="#e80054"
        d="M409.393,385.998v45c0,8.28-6.72,15-15,15c-8.28,0-15-6.72-15-15v-45H409.393z"
      />
      <path
        fill="#e80054"
        d="M339.393,385.998v45c0,8.28-6.72,15-15,15c-8.28,0-15-6.72-15-15v-45H339.393z"
      />
      <path
        fill="#e80054"
        d="M189.393,385.998v45c0,8.28-6.72,15-15,15c-8.28,0-15-6.72-15-15v-45H189.393z"
      />
      <path
        fill="#e80054"
        d="M119.393,385.998v45c0,8.28-6.72,15-15,15c-8.28,0-15-6.72-15-15v-45H119.393z"
      />
      <polygon
        fill="#ff4155"
        points="409.393,345.998 409.393,385.998 379.393,385.998 339.393,385.998 309.393,385.998 309.393,345.998"
      />
      <polygon
        fill="#ff4155"
        points="189.393,345.998 189.393,385.998 159.393,385.998 119.393,385.998 89.393,385.998 89.393,345.998"
      />
      <polygon
        fill="#ffd400"
        points="337.668,170.998 158.639,170.998 248.927,110.198"
      />
      <path
        fill="#c86d36"
        d="M329.163,170.998l-80.31-60.8l-81.71,60.8l-93.99,69.94c-3.97,2.95-8.63,4.4-13.28,4.4 c-5.73,0-11.44-2.21-15.75-6.52c-9.6-9.61-8.44-25.5,2.46-33.6l202.55-150.72l121.77,92.19v-36.09c0-13.65,10.94-24.74,24.52-25 c14.01-0.26,25.48,11.54,25.48,25.56v73.39l31.45,23.8c10.77,8.16,11.86,23.94,2.31,33.5l-0.039,0.03 c-7.86,7.86-20.32,8.72-29.181,2.01L329.163,170.998z"
      />
      <path d="M438.873,253.402c7.285,0,14.134-2.836,19.32-8.022c11.697-11.697,10.353-31.039-2.822-41.013l-29.466-22.308v-70.9 c0-17.043-13.818-30.879-30.578-30.557c-16.224,0.307-29.422,13.763-29.422,29.994v26.039L252.146,50.513 c-1.772-1.342-4.219-1.352-6.002-0.025L43.592,201.203c-13.344,9.929-14.751,29.4-3.001,41.148 c9.665,9.666,24.795,10.598,35.549,2.594l92.66-68.947h158.683l94.941,71.876C427.199,251.49,432.887,253.402,438.873,253.402 C438.873,253.402,438.873,253.402,438.873,253.402z M182.239,165.998l66.588-49.547l65.446,49.547H182.239z M251.871,106.214 c-1.771-1.342-4.22-1.353-6.002-0.025L70.17,236.923c-3,2.233-6.561,3.413-10.297,3.413c-10.065,0-17.949-8.546-17.222-18.519 c0.365-5.006,2.884-9.596,6.91-12.592L249.102,60.751l118.785,89.927c1.511,1.144,3.544,1.336,5.243,0.491 c1.7-0.845,2.774-2.579,2.774-4.477v-36.096c0-10.821,8.798-19.791,19.611-19.996l0.37-0.003 c10.851,0,20.019,9.417,20.019,20.563v73.386c0,1.566,0.733,3.041,1.982,3.986l31.448,23.808 c3.979,3.013,6.459,7.598,6.802,12.578c0.678,9.84-7.12,18.485-17.265,18.484c-3.788,0-7.389-1.21-10.413-3.499 C407.124,223.749,268.452,118.767,251.871,106.214z" />
      <path d="M464.785,130.421c3.821-1.597,5.625-5.99,4.028-9.812c-1.597-3.822-5.989-5.626-9.811-4.029 c-3.821,1.597-5.626,5.99-4.028,9.812C456.57,130.214,460.963,132.017,464.785,130.421z" />
      <path d="M369.785,260.42c3.821-1.597,5.625-5.989,4.028-9.811c-1.597-3.822-5.989-5.626-9.811-4.029 c-3.821,1.597-5.625,5.99-4.028,9.812C361.57,260.213,365.963,262.017,369.785,260.42z" />
      <path d="M149.78,240.417c3.822-1.597,5.627-5.99,4.029-9.812c-1.596-3.822-5.989-5.626-9.811-4.029 c-3.822,1.597-5.627,5.99-4.029,9.812C141.565,240.21,145.959,242.013,149.78,240.417z" />
      <path d="M44.003,396.58c-3.822,1.597-5.627,5.989-4.029,9.812c1.596,3.821,5.989,5.625,9.811,4.028 c3.822-1.597,5.627-5.989,4.029-9.811C52.218,396.787,47.824,394.984,44.003,396.58z" />
      <path d="M358.998,456.58c-3.821,1.597-5.625,5.989-4.028,9.812c1.597,3.821,5.989,5.625,9.812,4.028 c3.821-1.597,5.626-5.989,4.028-9.811C367.213,456.787,362.82,454.984,358.998,456.58z" />
      <path d="M54.784,135.417c3.822-1.597,5.627-5.99,4.029-9.812c-1.596-3.822-5.989-5.626-9.811-4.029 c-3.822,1.597-5.627,5.99-4.029,9.812C46.569,135.21,50.963,137.013,54.784,135.417z" />
      <path d="M339.781,55.421c3.821-1.597,5.625-5.99,4.028-9.812c-1.597-3.822-5.989-5.626-9.812-4.029 c-3.821,1.597-5.625,5.99-4.028,9.812C331.566,55.214,335.959,57.017,339.781,55.421z" />
      <path d="M94.391,115.998c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S75.093,115.998,94.391,115.998z M94.391,55.998c13.785,0,25,11.215,25,25s-11.215,25-25,25s-25-11.215-25-25S80.606,55.998,94.391,55.998z" />
      <path d="M409.391,269.998c-2.762,0-5,2.238-5,5v66h-95c-2.762,0-5,2.238-5,5c0,19.236,0,71.188,0,85c0,11.028,8.972,20,20,20 c11.028,0,20-8.972,20-20v-40h30v40c0,11.028,8.972,20,20,20c11.028,0,20-8.972,20-20c0-32.877,0-122.863,0-156 C414.391,272.237,412.153,269.998,409.391,269.998z M314.391,350.998h90v30c-21.958,0-68.047,0-90,0V350.998z M334.391,430.998 c0,5.514-4.486,10-10,10s-10-4.486-10-10v-40h20V430.998z M404.391,430.998c0,5.514-4.486,10-10,10s-10-4.486-10-10v-40h20 V430.998z" />
      <path d="M285.391,440.998h-15v-120h55c13.785,0,25-11.215,25-25c0-13.785-11.215-25-25-25h-150c-13.785,0-25,11.215-25,25 c0,13.785,11.215,25,25,25h55v120h-15c-2.762,0-5,2.238-5,5c0,2.762,2.238,5,5,5c21.449,0,26.563,0,50,0h20c2.762,0,5-2.238,5-5 C290.391,443.237,288.153,440.998,285.391,440.998z M160.391,295.998c0-8.271,6.729-15,15-15h150c8.272,0,15,6.729,15,15 c0,8.272-6.728,15-15,15c-21.777,0-130.477,0-150,0C167.12,310.998,160.391,304.27,160.391,295.998z M240.391,440.998v-120h20 v120H240.391z" />
      <path d="M189.391,340.998h-95v-66c0-2.762-2.238-5-5-5c-2.762,0-5,2.238-5,5c0,18.229,0,140.472,0,156c0,11.028,8.972,20,20,20 s20-8.972,20-20v-40h30v40c0,11.028,8.972,20,20,20c11.028,0,20-8.972,20-20v-45v-40 C194.391,343.237,192.153,340.998,189.391,340.998z M184.391,380.998c-21.958,0-68.047,0-90,0v-30h90V380.998z M114.391,430.998 c0,5.514-4.486,10-10,10c-5.514,0-10-4.486-10-10v-40h20V430.998z M184.391,430.998c0,5.514-4.486,10-10,10 c-5.514,0-10-4.486-10-10v-40h20V430.998z" />
      <path d="M299.391,200.998c2.762,0,5-2.239,5-5s-2.238-5-5-5h-100c-2.762,0-5,2.239-5,5s2.238,5,5,5H299.391z" />
      <path d="M454.391,390.998c-13.785,0-25,11.215-25,25c0,13.785,11.215,25,25,25c13.785,0,25-11.215,25-25 C479.391,402.213,468.177,390.998,454.391,390.998z M454.391,430.998c-8.271,0-15-6.728-15-15c0-8.271,6.729-15,15-15 c8.272,0,15,6.729,15,15C469.391,424.27,462.663,430.998,454.391,430.998z" />
    </svg>
  );
}

export default DashboardIcon;
