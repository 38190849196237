import axios from "axios";
import fileDownload from "js-file-download";
import { toast } from "react-toastify";
import { setMiscLoading, setReloadAPI } from ".";
import { user_http} from "../../https/user_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchStoreApprovalList = (status) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/up/up/store?status=${status}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_APPROVAL_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchPartnerAMList = (status) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/up/store/area-manager?status=${status}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_AREA_MANAGER_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};


export const approveStoreOnboarding = (data, id, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/up/store/creds/${id}`, data)
      .then(async () => {
        cb();
        await dispatch(setMiscLoading(false));
        await dispatch(setReloadAPI(true));
        toast.success("Username generated");
      })
      .catch(() => {
        toast.error("Error while generating user name");
        dispatch(setMiscLoading(false));
      });
  };
};

export const initiatePartnerOnBoarding = (data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/up/store/initiate`, data)
      .then((res) => {
        cb(res.data);
        toast.success("Initiated successfully");
        dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("partner onboarding failed");
        dispatch(setMiscLoading(false));
      });
  };
};

export const getStoreDetails = (id, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(`${API_URL}/up/up/store/${id}`)
      .then((res) => {
        if (cb) cb(res.data);
        dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const ownerUpdateStoreDetails = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .put(`${API_URL}/up/store/${id}/owner/update`, data)
      .then((res) => {
        if (cb) cb(res.data);
        toast.success("Application submitted successfully!");
        dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error(
          "Error while submitting, fill all mandatory fields and upload file size less than 2 MB"
        );
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateStoreDetails = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/up/store/${id}/update`, data)
      .then(async (res) => {
        if (res.data.status === "created") {
          await dispatch({
            type: actionTypes.UPDATE_STORE_LIST,
            payload: res.data,
          });
        } else {
          await dispatch({
            type: actionTypes.UPDATE_STORE_APPROVAL_LIST,
            payload: res.data,
          });
        }
        if(cb) {
          cb();
        }
        await dispatch(setMiscLoading(false));
        toast.success("Updated !");
      })
      .catch(() => {
        toast.error("Unable to update Store");
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateStoreBrandDetails = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/up/store/${id}/update/brand`, data)
      .then(async (res) => {
        if (res.data.status === "created") {
          await dispatch({
            type: actionTypes.UPDATE_STORE_LIST,
            payload: res.data,
          });
        } else {
          await dispatch({
            type: actionTypes.UPDATE_STORE_APPROVAL_LIST,
            payload: res.data,
          });
        }
        if(cb) {
          cb();
        }
        await dispatch(setMiscLoading(false));
        toast.success("Updated !");
      })
      .catch(() => {
        toast.error("Unable to update Store brands");
        dispatch(setMiscLoading(false));
      });
  };

}

export const fetchStoreList = (nickname) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    let url = `${API_URL}/up/store?status=created`;
    if(nickname) url+=`&nickname=${nickname}`
    user_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const downloadAllPartners = () => {
  
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/up/store/download`,{
        responseType: 'arraybuffer'
      })
      .then(async (response) => {
        fileDownload(response.data, `partner_${new Date()}.xls`);
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
}


export const fetchStoreStatusCount = () => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/up/store/count`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_STATUS_COUNT,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchPartnerPaymentList = (from, to) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/partner-payments?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_PAYMENT_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};


export const uploadPartnerPaymentInvoice = (invoice, id) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    let formData = new FormData();
    formData.append("invoice", invoice);
    user_http
      .put(`${API_URL}/partner-payments/${id}`, formData)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_PARTNER_PAYMENT_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};


export const fetchPartnerPaymentLinkList = () => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/partner-payments/link`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_PAYMENT_LINK_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const createNewPartnerPaymentList = (data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/partner-payments`, data)
      .then(() => {
        cb();
        dispatch(setMiscLoading(false));
        toast.success("Created new payment !");
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};


export const verifyPartnerPayment = (data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/partner-payments/link/verify`, data)
      .then(() => {
        cb();
        dispatch(setMiscLoading(false));
        toast.success("Payment verified ...");
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
        toast.error("unable to verify payments !");
      });
  };
};

export const createNewPartnerPaymentLink = (data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/partner-payments/link`, data)
      .then(() => {
        cb();
        dispatch(setMiscLoading(false));
        toast.success("Created new payment link!");
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
}

export const sendAggregatorOnboardingEmail = (aggregator, storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/up/store/aggregate/${storeID}`, {
        aggregators: [aggregator],
      })
      .then(async (res) => {
        toast.success(`Mail sent to ${aggregator} team`);
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const sendRistaInitiationEmail = (storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/up/store/aggregate/${storeID}/rista_initiate`, {})
      .then(async (res) => {
        toast.success(`Mail sent to rista team`);
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
}

export const sendRistaTrainingEmail = (data, storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/up/store/aggregate/${storeID}/rista_training`, data)
      .then(async (res) => {
        toast.success(`Mail sent to rista training team`);
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const uploadFssaiReceipt = (file, storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    user_http
      .put(`${API_URL}/up/store/${storeID}/upload/fssai-receipt`, formData, {
        "Content-Type": "multipart/form-data"
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};


export const uploadFssaiCertificate = (file, fssai_expiry, storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify({fssai_expiry}));
    user_http
      .put(`${API_URL}/up/store/${storeID}/upload/fssai-certificate`, formData, {
        "Content-Type": "multipart/form-data"
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const updatePartnerState = (state, storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/up/store/${storeID}/update/state`, {state})
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const getStoreBrandMapping = (storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    dispatch({
      type: actionTypes.SET_STORE_BRAND_MAPPING,
      payload: [],
    });
    user_http
      .get(`${API_URL}/brand/${storeID}/mapping`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_BRAND_MAPPING,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateStoreBrandMapping = (data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/brand/update/mapping`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_BRAND_MAPPING,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const getStoreBrandZSUrls = (storeID) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    dispatch({
      type: actionTypes.SET_STORE_BRAND_ZS_URL,
      payload: [],
    });
    user_http
      .get(`${API_URL}/brand/${storeID}/zsmapping`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_STORE_BRAND_ZS_URL,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateStoreBrandZSUrls= (data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/brand/update/zsmapping`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_BRAND_ZS_URL,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateStoreBrandDiscount = (data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/brand/update/mapping/discount`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_STORE_BRAND_MAPPING,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchEmployeeSalesSummary = (query) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/sale/summary?${query}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_SALES_SUMMARY,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get data !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchEmployeeSalesInsights = (from, to) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/sale/insights?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_SALES_INSIGHTS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get data !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchEmployeePayoutSchedules = (from, to) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/payout-schedules?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_PAYOUT_SCHEDULES,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get data !')
        dispatch(setMiscLoading(false));
      });
  };
};


export const fetchEmployeeHourlySales = (from, to) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/analytics/sale/summary-by-week?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_HOURLY_SALES,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get data !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchPartnerPayoutSchedules = () => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/payout-schedules/partner`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_PAYOUT_SCHEDULES,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get data !')
        dispatch(setMiscLoading(false));
      });
  };
};


export const uploadEmployeePayoutSchedules = (id, formData, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/payout-schedules/${id}/upload`, formData)
      .then(async (res) => {
        await dispatch(setMiscLoading(false));
        cb();
      })
      .catch(() => {
        toast.error('Unable to upload !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const downloadPayoutSchedules = (id) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/payout-schedules/${id}/download`,{
        responseType: 'arraybuffer'
      })
      .then(async (response) => {
        fileDownload(response.data, `${id}.xlsx`);
        dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};

export const createNewPayoutSchedules = (data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/payout-schedules`, data)
      .then(() => {
        dispatch(setMiscLoading(false));
        cb();
      })
      .catch(() => {
        toast.error('Unable to create new schedules !')
        dispatch(setMiscLoading(false));
      });
  };
}

export const fetchMerchantSalesInsights = (from, to) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/sale/insights/partner?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_EMPLOYEE_SALES_INSIGHTS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get data !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const uploadPartnerExcel = (file) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append("file", file);
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/up/store/upload/bulk`, formData, {
        "Content-Type": "multipart/form-data"
      })
      .then(async (res) => {
        toast.success(`
          Added: ${res.data?.added_count},
          Error: ${res.data?.error_count}
        `)
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Something went wrong, please check employee list')
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchRistaItemsOutOfStock = () => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/rista/item/out-of-stock/report`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_RISTA_ITEMS_OUT_OF_STOCK,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get data !')
        dispatch(setMiscLoading(false));
      });
  };
};


export const downloadPartnersSwiggyZomatoDetails = () => {
  
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/brand/download/zsbrand`,{
        responseType: 'arraybuffer'
      })
      .then(async (response) => {
        fileDownload(response.data, `swiggy_zomato_details_${new Date()}.xlsx`);
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
}

export const uploadPartnersSwiggyZomatoDetails = (file) => {
  
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    let formData = new FormData();
    formData.append("file", file);
    user_http
      .post(`${API_URL}/brand/upload/zsbrand`, formData, {
        "Content-Type": "multipart/form-data"
      })
      .then(async () => {
        toast.success('uploaded successfully')
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
}
