export const SET_MISC_LOADING = "SET_MISC_LOADING";
export const SET_INVOICES = "SET_INVOICES";
export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const SET_EVENTS = "SET_EVENTS";
export const ADD_EVENT = "ADD_EVENT";

export const SET_RELOAD_API = "SET_RELOAD_API";
export const SET_CHECKLISTS = "SET_CHECKLISTS";
export const CREATE_CHECKLISTS = "CREATE_CHECKLISTS";
export const SET_MY_CHECKLISTS = "SET_MY_CHECKLISTS";
export const SET_CHECKLIST_RESPONSES = "SET_CHECKLIST_RESPONSES";
export const SET_APPROVAL_RESPONSES = "SET_APPROVAL_RESPONSES";
export const SET_MANAGED_DOCUMENTS = "SET_MANAGED_DOCUMENTS";
