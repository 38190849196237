export const SET_MY_TICKETS = "SET_MY_TICKETS";
export const UPDATE_MY_TICKETS_PAGINATION = "UPDATE_MY_TICKETS_PAGINATION";
export const ADD_TO_MY_TICKETS = "ADD_TO_MY_TICKETS";
export const SET_ALL_TICKETS = "SET_ALL_TICKETS";
export const UPDATE_ALL_TICKETS_PAGINATION = "UPDATE_ALL_TICKETS_PAGINATION";
export const UPDATE_ALL_TICKETS = "UPDATE_ALL_TICKETS";
export const SET_ASSIGNED_TICKETS = "SET_ASSIGNED_TICKETS";
export const UPDATE_ASSIGNED_TICKETS_PAGINATION =
  "UPDATE_ASSIGNED_TICKETS_PAGINATION";
export const SET_VIEW_TICKET = "SET_VIEW_TICKET";
export const SET_TICKET_DETAILS = "SET_TICKET_DETAILS";
export const SET_PARTNER_MY_TICKETS = "SET_PARTNER_MY_TICKETS";
export const UPDATE_PARTNER_MY_TICKETS_PAGINATION =
  "UPDATE_PARTNER_MY_TICKETS_PAGINATION";
export const ADD_TO_PARTNER_MY_TICKETS = "ADD_TO_PARTNER_MY_TICKETS";
