import axios from "axios";
import { toast } from "react-toastify";
import {  setReloadAPI, setMiscLoading } from ".";
import { user_http } from "../../https/user_http";

import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchMyTickets = (status, limit) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
user_http
      .get(`${API_URL}/ticket/tenant?status=${status}&page=1&limit=${limit}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_MY_TICKETS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchMyTicketsPagination = (url) => {
  return (dispatch) => {
user_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_MY_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};


export const fetchPartnerMyTickets = (status, limit) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/ticket/partner?status=${status}&page=1&limit=${limit}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARTNER_MY_TICKETS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchPartnerMyTicketsPagination = (url) => {
  return (dispatch) => {
    user_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_PARTNER_MY_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const fetchAllTickets = (status, limit, assignedTeam) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    let url = `${API_URL}/ticket?status=${status}&page=1&limit=${limit}`;
    if(assignedTeam && assignedTeam !== 'all') {
      url+=`&team=${assignedTeam}`
    }
user_http
      .get(url)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ALL_TICKETS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchAllTicketsPagination = (url) => {
  return (dispatch) => {
user_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_ALL_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const createNewTicket = (formData, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
user_http
      .post(`${API_URL}/ticket`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.ADD_TO_MY_TICKETS,
          payload: res.data,
        });
        cb();
      })
      .catch(() => {
        toast.error("Unable to create ticket !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const createNewTenantTicket = (formData, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/ticket/tenant`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.ADD_TO_PARTNER_MY_TICKETS,
          payload: res.data,
        });
        cb();
      })
      .catch(() => {
        toast.error("Unable to create ticket !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateAssignedTeam = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
user_http
      .put(`${API_URL}/ticket/${id}`, data)
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.UPDATE_ALL_TICKETS,
          payload: res.data,
        });
        cb();
      })
      .catch(() => {
        toast.error("Unable to update ticket !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchAssignedTickets = (status, limit) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
user_http
      .get(`${API_URL}/ticket?status=${status}&page=1&limit=${limit}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ASSIGNED_TICKETS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get tickets !");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchAssignedTicketsPagination = (url) => {
  return (dispatch) => {
user_http
      .get(url)
      .then((res) => {
        dispatch({
          type: actionTypes.UPDATE_ASSIGNED_TICKETS_PAGINATION,
          payload: res.data,
        });
      })
      .catch(() => {});
  };
};

export const setViewTicket = (ticketId) => ({
  type: actionTypes.SET_VIEW_TICKET,
  payload: ticketId,
});

export const getIndividualTicketDetails = (ticketId) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    axios
      .get(`${API_URL}/ticket/${ticketId}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to get ticket details !");
        dispatch(setMiscLoading(false));
      });
  };
}

export const addTicketComments = (ticketId, formData) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
user_http
      .post(`${API_URL}/ticket/${ticketId}/comment`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to add comments !");
        dispatch(setMiscLoading(false));
      });
  };
}

export const addTenantTicketComments = (ticketId, formData) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/ticket/${ticketId}/comment/tenant`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to add comments !");
        dispatch(setMiscLoading(false));
      });
  };
}

export const setTicketStatus = (ticketId, status) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
user_http
      .put(`${API_URL}/ticket/${ticketId}`, {
        status
      })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_TICKET_DETAILS,
          payload: res.data,
        });
        await dispatch(setReloadAPI(true));
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to update status!");
        dispatch(setMiscLoading(false));
      });
  };
}