import React from 'react';

function PaymentIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <g data-name="27-Cash">
        <polygon
          fill="#6a8c45"
          points="45 55 45 57 15 57 15 3 19 3 19 23 19 31 19 53 45 53 45 55"
        />
        <path
          fill="#f2f6fc"
          d="M49,26v5H43l-2-2a5.429,5.429,0,0,0-1.28,2H19V23H49Z"
        />
        <path
          fill="#ffc170"
          d="M27.08,31H39.72a5.455,5.455,0,0,0-.38,1.95A7.983,7.983,0,0,1,27.08,31Z"
        />
        <path fill="#ffc170" d="M40.92,23H27.08a7.986,7.986,0,0,1,13.84,0Z" />
        <polygon fill="#85ad59" points="49 31 49 36 48 36 43 31 49 31" />
        <path
          fill="#85ad59"
          d="M45,45V44l.03-.04A7.232,7.232,0,0,0,49,45v8H19V31h4V45a4,4,0,0,1,4,4H41A4,4,0,0,1,45,45Z"
        />
        <path
          fill="#85ad59"
          d="M49,23H45V9a4,4,0,0,1-4-4H27a4,4,0,0,1-4,4V23H19V1H49V23Z"
        />
        <path
          fill="#aac864"
          d="M45.03,43.96,45,44v1a4,4,0,0,0-4,4H27a4,4,0,0,0-4-4V31h4.08a7.983,7.983,0,0,0,12.26,1.95V33A5.65,5.65,0,0,0,41,37l1,1S42,42.01,45.03,43.96Z"
        />
        <path
          fill="#aac864"
          d="M45,9V23H40.92a7.986,7.986,0,0,0-13.84,0H23V9a4,4,0,0,0,4-4H41A4,4,0,0,0,45,9Z"
        />
        <polygon fill="#ff636e" points="63 51 63 63 51 63 63 51" />
        <polygon
          fill="#ff9696"
          points="63 51 51 63 47 59 48 58 52.5 53.5 58 48 59 47 63 51"
        />
        <polygon
          fill="#faa68e"
          points="52.5 53.5 48 58 45 55 45 53 49 53 49 45 51 45 51 52 52.5 53.5"
        />
        <polygon fill="#faa68e" points="51 28 51 36 49 36 49 31 49 26 51 28" />
        <path
          fill="#ffcdbe"
          d="M56,46l2,2-5.5,5.5L51,52V45H49a7.232,7.232,0,0,1-3.97-1.04C42,42.01,42,38,42,38l-1-1a5.65,5.65,0,0,1-1.66-4v-.05A5.822,5.822,0,0,1,41,29l2,2,5,5h3V28l-2-2V22l5,3Z"
        />
        <rect width="4" height="8" x="19" y="23" fill="#cdd2e1" />
        <rect width="4" height="8" x="45" y="23" fill="#cdd2e1" />
        <path d="M63.707,50.293l-4-4a1,1,0,0,0-1.414,0L58,46.586l-1.039-1.039L55,24.905a1,1,0,0,0-.481-.762L50,21.434V1a1,1,0,0,0-1-1H19a1,1,0,0,0-1,1V2H15a1,1,0,0,0-1,1V57a1,1,0,0,0,1,1H45a.992.992,0,0,0,.93-.656l.656.656-.293.293a1,1,0,0,0,0,1.414l4,4A1,1,0,0,0,51,64H63a1,1,0,0,0,1-1V51A1,1,0,0,0,63.707,50.293ZM53.052,25.6,55,46.1a1,1,0,0,0,.289.612L56.586,48,48,56.586l-2-2V54h3a1,1,0,0,0,1-1V46h1V44H49c-5.8,0-6-5.389-6-6a1,1,0,0,0-.293-.707l-1-1a4.665,4.665,0,0,1-.63-5.8l6.216,6.217A1,1,0,0,0,48,37h3V35H50V23.766ZM44,22H41.453a8.943,8.943,0,0,0-14.906,0H24V9.9A5.013,5.013,0,0,0,27.9,6H40.1A5.013,5.013,0,0,0,44,9.9Zm-5.11,0H29.111a6.977,6.977,0,0,1,9.779,0ZM48,24v6H43.414l-1.707-1.707a1,1,0,0,0-1.414,0A6.623,6.623,0,0,0,39.062,30H20V24Zm-2.586,8H48v2.586ZM24,32h2.547a8.94,8.94,0,0,0,12.034,2.736,6.607,6.607,0,0,0,1.712,2.971l.72.72a8.457,8.457,0,0,0,2.678,5.761A5.006,5.006,0,0,0,40.1,48H27.9A5.013,5.013,0,0,0,24,44.1Zm14.372.451A6.961,6.961,0,0,1,29.111,32h9.318C38.406,32.15,38.385,32.3,38.372,32.451ZM20,32h2V45a1,1,0,0,0,1,1,3,3,0,0,1,3,3,1,1,0,0,0,1,1H41a1,1,0,0,0,1-1,3,3,0,0,1,3-3,.988.988,0,0,0,.865-.528A8.381,8.381,0,0,0,48,45.95V52H20ZM48,2V22H46V9a1,1,0,0,0-1-1,3,3,0,0,1-3-3,1,1,0,0,0-1-1H27a1,1,0,0,0-1,1,3,3,0,0,1-3,3,1,1,0,0,0-1,1V22H20V2ZM16,56V4h2V53a1,1,0,0,0,1,1H44v2Zm32.414,3,.293-.293,10-10L59,48.414,61.586,51,51,61.586Zm5,3L62,53.414V62Z" />
      </g>
    </svg>
  );
}

export default PaymentIcon;
