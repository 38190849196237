import React from 'react';

function LogoutIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path fill="#ed7899" d="M29 4A28 28 0 1 0 29 60A28 28 0 1 0 29 4Z" />
      <path
        fill="#f9e3ae"
        d="M29,4A28,28,0,1,0,57,32,28,28,0,0,0,29,4Zm0,50A22,22,0,1,1,44.17,16.07l.68.67q.39.4.75.82A22,22,0,0,1,29,54Z"
      />
      <path
        fill="#f6d397"
        d="M29,54A22,22,0,0,1,7,32H5.34a4,4,0,0,0-3.95,4.71,28,28,0,0,0,55.21,0A4,4,0,0,0,52.66,32H51A22,22,0,0,1,29,54Z"
      />
      <path
        fill="#8d6c9f"
        d="M29,3A29,29,0,1,0,58,32,29,29,0,0,0,29,3Zm0,56A27,27,0,1,1,56,32,27,27,0,0,1,29,59Z"
      />
      <path
        fill="#8d6c9f"
        d="M29 51a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0V52A1 1 0 0 0 29 51zM35 51.08a1 1 0 0 0-1.93.52l.52 1.93A1 1 0 0 0 35.53 53zM19.75 48.52a1 1 0 0 0-1.37.37l-1 1.73a1 1 0 0 0 1.73 1l1-1.73A1 1 0 0 0 19.75 48.52zM39.62 48.89a1 1 0 0 0-1.73 1l1 1.73a1 1 0 0 0 1.73-1zM24.21 50.37a1 1 0 0 0-1.22.71L22.47 53a1 1 0 1 0 1.93.52l.52-1.93A1 1 0 0 0 24.21 50.37zM36.35 12.32a21.2 21.2 0 0 1 2.1.92 1 1 0 1 0 .9-1.79 22.82 22.82 0 0 0-2.3-1 1 1 0 1 0-.7 1.87zM14.15 17.15a21.08 21.08 0 0 1 18.19-5.89 1 1 0 1 0 .32-2 23 23 0 0 0-19.92 39 1 1 0 0 0 1.41-1.41A21 21 0 0 1 14.15 17.15zM45.26 15.74a23.35 23.35 0 0 0-2.09-1.86 1 1 0 1 0-1.23 1.57 21.35 21.35 0 0 1 1.91 1.7 21 21 0 0 1 0 29.7 1 1 0 1 0 1.41 1.41A23 23 0 0 0 45.26 15.74z"
      />
      <path
        fill="#faefde"
        d="M23 29L52 29 48 25 52 21 63 32 52 43 48 39 52 35 23 35 23 29z"
      />
      <path
        fill="#8d6c9f"
        d="M63.71,31.29l-11-11a1,1,0,0,0-1.41,0l-4,4a1,1,0,0,0,0,1.41L49.59,28H23a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1H49.59l-2.29,2.29a1,1,0,0,0,0,1.41l4,4a1,1,0,0,0,1.41,0l11-11A1,1,0,0,0,63.71,31.29ZM52,41.59,49.41,39l3.29-3.29A1,1,0,0,0,52,34H24V30H52a1,1,0,0,0,.71-1.71L49.41,25,52,22.41,61.59,32Z"
      />
    </svg>
  );
}

export default LogoutIcon;
