import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { miscReducers } from "./miscReducers";
import { authReducers } from "./authReducers";
import { userReducers } from "./userReducers";
import { ticketReducers } from "./ticketReducers";
import { storeDetailsReducers } from "./storeDetailsReducers";
import { parkingReducers } from "./parkingReducers";
import { analyticsReducers } from "./analyticsReducers";

const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    misc: miscReducers,
    auth: authReducers,
    user: userReducers,
    tickets: ticketReducers,
    storeDetails: storeDetailsReducers,
    parking: parkingReducers,
    analytics: analyticsReducers,
  });

export default reducers;
