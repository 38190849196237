import React from "react";
import Drawer from "@mui/material/Drawer";
import { useStyles } from "../styles";
import { useRouteMatch } from "react-router";
import MenuSelection from "../../MenuSelection";
import DashboardIcon from "../../../assets/header/svgs/DashboardIcon";
import ManageItemsIcon from "../../../assets/header/svgs/ManageItemsIcon";
import LogoutIcon from "../../../assets/header/svgs/LogoutIcon";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import UserInfo from "./UserInfo";
// import PreferencesIcon from "../../../assets/header/svgs/PreferencesIcon";
import SalesReportIcon from "../../../assets/header/svgs/SalesReportIcon";
// import AnalyticsIcon from "../../../assets/header/svgs/AnalyticsIcon";
// import ManageTableIcon from "../../../assets/header/svgs/ManageTableIcon";
// import ProfileIcon from "../../../assets/header/svgs/ProfileIcon";
import EmployeeIcon from "../../../assets/header/svgs/EmployeeIcon";
// import CrmIcon from "../../../assets/header/svgs/CrmIcon";
// import SubscriptionIcon from "../../../assets/header/svgs/SubscriptionIcon";
// import ActiveTableIcon from "../../../assets/header/svgs/ActiveTableIcon";
// import RetentionIcon from "../../../assets/header/svgs/RetentionIcon";
// import ExpensesIcon from "../../../assets/header/svgs/ExpensesIcon";
import PaymentIcon from "../../../assets/header/svgs/PaymentIcon";
// import SocialVoucherIcon from "../../../assets/header/svgs/SocialVoucherIcon";
// import MapIcon from "../../../assets/header/svgs/MapIcon";
// import LoyaltyIcon from "../../../assets/header/svgs/LoyaltyIcon";
// import SMSCampaignIcon from "../../../assets/header/svgs/SMSCampaignIcon";
// import WhatsappIcon from "../../../assets/header/svgs/WhatsappIcon";
// import LeadGenIcon from "../../../assets/header/svgs/LeadGenIcon";
// import GlideIcon from "../../../assets/header/svgs/GlideIcon";
import { userLogout } from "../../../redux/actions";
// import DesignIcon from "../../../assets/header/svgs/DesignIcon";

function MenuDrawer({ open, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth.loginData?.role);

  let menus = [
    {
      title: "",
      visible: true,
      elements: [
        {
          label: "Home",
          visible: true,
          active: useRouteMatch("/app/dashboard"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/dashboard"));
          },
          icon: <DashboardIcon />,
        },
      ],
    },
    {
      title: "Users",
      visible: role === "admin",
      elements: [
        {
          label: "Manage users",
          active: useRouteMatch("/app/manage-users"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/manage-users"));
          },
          visible: role === "admin",
          icon: <EmployeeIcon />,
        },
      ],
    },
    {
      title: "Analytics",
      visible: role === "admin",
      elements: [
        {
          label: "Sales Overview",
          active: useRouteMatch("/app/sales-overview"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/sales-overview"));
          },
          visible: role === "admin",
          icon: <SalesReportIcon />,
        },{
          label: "Rent Overview",
          active: useRouteMatch("/app/rent-overview"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/rent-overview"));
          },
          visible: role === "admin",
          icon: <SalesReportIcon />,
        },
      ],
    },
    {
      title: "Invoice",
      visible: role === "admin",
      elements: [
        {
          label: "Generate Invoice",
          active: useRouteMatch("/app/generate-invoice"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/generate-invoice"));
          },
          visible: role === "admin",
          icon: <PaymentIcon />,
        },{
          label: "All Invoices",
          active: useRouteMatch("/app/invoices"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/invoices"));
          },
          visible: role === "admin",
          icon: <ManageItemsIcon />,
        },
      ],
    },
    {
      title: "Tickets",
      visible: role === "admin" || role === "tenant",
      elements: [
        {
          label: "All Tickets",
          active: useRouteMatch("/app/all-tickets"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/all-tickets"));
          },
          visible: role === "admin",
          icon: <ManageItemsIcon />,
        },
        {
          label: "My Tickets",
          active: useRouteMatch("/app/my-tickets"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/my-tickets"));
          },
          visible: role === "tenant",
          icon: <ManageItemsIcon />,
        },
      ],
    },
    {
      title: "Checklists",
      visible: true,
      elements: [
        {
          label: "Checklists",
          active: useRouteMatch("/app/checklists"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/checklists"));
          },
          visible: role === "admin",
          icon: <ManageItemsIcon />,
        },
        {
          label: "My Checklists",
          active: useRouteMatch("/app/my-checklists"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/my-checklists"));
          },
          visible: true,
          icon: <ManageItemsIcon />,
        },
        {
          label: "Approval Checklists",
          active: useRouteMatch("/app/approve-checklists"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/approve-checklists"));
          },
          visible: true,
          icon: <ManageItemsIcon />,
        },
      ],
    },
    {
      title: "Document Management",
      visible: true,
      elements: [
        {
          label: "Document Management",
          active: useRouteMatch("/app/document-management"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/document-management"));
          },
          visible: role === "admin",
          icon: <ManageItemsIcon />,
        }
      ],
    },
    {
      title: "Parking",
      visible: role === "admin" || role === "parking",
      elements: [
        {
          label: "Parking config",
          active: useRouteMatch("/app/parking-config"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/parking-config"));
          },
          visible: role === "admin",
          icon: <ManageItemsIcon />,
        },
        {
          label: "Parking Report",
          active: useRouteMatch("/app/parking-report"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/parking-report"));
          },
          visible: role === "admin",
          icon: <ManageItemsIcon />,
        },
        {
          label: "Parking Details",
          active: useRouteMatch("/app/parking-details"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/parking-details"));
          },
          visible: role === "parking",
          icon: <ManageItemsIcon />,
        },
      ],
    },
    {
      title: "Event Management",
      visible: role === "admin",
      elements: [
        {
          label: "Event List",
          active: useRouteMatch("/app/events"),
          handleMenu: () => {
            handleClose();
            dispatch(push("/app/events"));
          },
          visible: role === "admin",
          icon: <ManageItemsIcon />,
        },
      ],
    },
    {
      title: "",
      visible: true,
      elements: [
        {
          label: "Logout",
          active: false,
          visible: true,
          handleMenu: () => {
            handleClose();
            dispatch(userLogout());
          },
          icon: <LogoutIcon />,
        },
      ],
    },
  ];

  return (
    <Drawer open={open} onClose={handleClose}>
      <div className={classes.drawerRoot}>
        <UserInfo />
        {menus
          .filter((a) => a.visible)
          .map((subMenu, idx) => (
            <div key={idx} style={{ marginBottom: 16 }}>
              {subMenu.title && (
                <div
                  style={{ margin: "0px 16px 8px", fontSize: 18, opacity: 0.6 }}
                >
                  <fieldset
                    style={{
                      borderTop: "0.5px solid lightgray",
                      borderLeft: "none",
                      borderBottom: "none",
                      borderRight: "none",
                      padding: 0,
                    }}
                  >
                    <legend>{subMenu.title}&nbsp;</legend>
                  </fieldset>
                </div>
              )}
              {subMenu.elements
                .filter((a) => a.visible)
                .map((menu, index) => (
                  <MenuSelection
                    key={index}
                    title={subMenu.title}
                    active={menu.active}
                    label={menu.label}
                    handleClick={menu.handleMenu}
                  >
                    {menu.icon}
                  </MenuSelection>
                ))}
            </div>
          ))}
        <div className={classes.bottomArea}>
          <div className={classes.version}>
            V-{window.localStorage.getItem("appVersion")}
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default MenuDrawer;
