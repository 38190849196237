import { ThemeProvider } from "@mui/material/styles";
import * as React from "react";
import { Switch } from "react-router";
import suspenseHoc from "./hoc/suspenseHoc";
import { lightTheme, darkTheme } from "./utils/theme";
import LoginPageHoc from "./hoc/LoginPageHoc";
import headerHoc from "./hoc/headerHoc";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserAuthHoc from "./hoc/UserAuthHoc";
import LoadingModal from "./components/LoadingModal";

const LoginPage = React.lazy(() => import("./pages/LoginPage"));
const UserRoutes = React.lazy(() => import("./routes"));


function App() {

  React.useEffect(() => {
    window.onload = function () {
      const appVersion = window.localStorage.getItem("appVersion");
      if (!appVersion) {
        window.localStorage.setItem("appVersion", "22.01.13m");
      } else if (appVersion !== "22.01.13m") {
        toast.info("Please wait, updating new version...");
        setTimeout(() => {
          window.localStorage.clear();
          window.localStorage.setItem("appVersion", "22.01.13m");
          window.location.reload();
        }, 3000);
      }
    };
  }, []);
  const theme = window.localStorage.theme ? window.localStorage.theme : "light";
  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <Switch>
        <LoginPageHoc exact path="/" component={suspenseHoc(LoginPage)} />
        <UserAuthHoc
          path="/app"
          component={suspenseHoc(headerHoc(UserRoutes))}
        />
      </Switch>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange={false}
        draggable
        pauseOnHover={false}
      />
      <LoadingModal />
    </ThemeProvider>
  );
}

export default App;
