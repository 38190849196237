import * as actionTypes from "../actionTypes";
import {SET_ANALYTICS_RENT_OVERVIEW} from "../actionTypes";

let initialState = {
  salesOverview: [],
  rentOverview: [],
};

export const analyticsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_ANALYTICS_SALES_OVERVIEW:
      return {
        ...state,
        salesOverview: action.payload,
      };
    case actionTypes.SET_ANALYTICS_RENT_OVERVIEW:
      return {
        ...state,
        rentOverview: action.payload,
      };
    case actionTypes.SET_USER_LOGOUT:
      return {
        salesOverview: [],
      };
    default:
      return state;
  }
};
