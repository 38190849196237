import { toast } from "react-toastify";
import { setMiscLoading } from ".";
import { user_http } from "../../https/user_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchUserList = () => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/user`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_USER_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to get user list !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateUserStatus = (id, active) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/user/${id}`, { active })
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_USER_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error('Unable to update user list !')
        dispatch(setMiscLoading(false));
      });
  };
};


export const createNewUser = (data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/user`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_USER_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
        cb();
      })
      .catch(() => {
        toast.error('Unable to add new user !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const editUser = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/user/address/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_USER_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
        cb();
      })
      .catch(() => {
        toast.error('Unable to update user address !')
        dispatch(setMiscLoading(false));
      });
  };
};


export const updateUserSalarySettings = (id, data, cb) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/user/salary/${id}`, data)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.UPDATE_USER_LIST,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
        cb();
      })
      .catch(() => {
        toast.error('Unable to update user salary settings !')
        dispatch(setMiscLoading(false));
      });
  };
};

export const getAllUserAttendance = (date) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/user-attendance/daily?date=${date}`)
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.FETCH_USER_ATTENDANCE,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateUserAttendance = (id, data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/user-attendance/${id}`, data)
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.UPDATE_USER_ATTENDANCE,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setMiscLoading(false));
      });
  };
};

export const updateUserSalary = (data, date, type) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    const update = {
      amount: parseInt(data.pay_amount) ? parseInt(data.pay_amount) : 0,
      date: date,
      remarks: data.remarks,
    };
    user_http
      .post(`${API_URL}/user-salary/${data.employee._id}/pay`, update)
      .then(async (res) => {
        await dispatch(setMiscLoading(false));
        if (type === 'monthly') {
          await dispatch(getAllUserSalaryMonthly(date));
        }
        if (type === 'daily') {
          await dispatch(getAllUserSalaryDaily(date));
        }
        await dispatch({
          type: actionTypes.UPDATE_USER_SALARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setMiscLoading(false));
      });
  };
};

export const getAllUserSalaryMonthly = (date) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/user-salary/monthly?date=${date}`)
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.INIT_USER_SALARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setMiscLoading(false));
      });
  };
};

export const getAllUserSalaryDaily = (date) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/user-salary/daily?date=${date}`)
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.INIT_USER_SALARY,
          payload: res.data,
        });
      })
      .catch((err) => {
        if (err.response && err.response.data && err.response.data.message) {
          toast.error(err.response.data.message);
        }
        dispatch(setMiscLoading(false));
      });
  };
};
