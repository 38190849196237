import * as actionTypes from "../actionTypes";

let initialState = {
  parkingConfig: [],
  parkingReport: [],
  parkingSearch: {},
  parkingCheckout: {},
  searchLoading: false,
};

export const parkingReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PARKING_CONFIG:
      return {
        ...state,
        parkingConfig: action.payload,
      };
    case actionTypes.UPDATE_PARKING_CONFIG:
      let tempUpdateConfig = [...state.parkingConfig];
      const tempUpdateConfigIndex = tempUpdateConfig.findIndex(
        (a) => a._id === action.payload._id
      );
      if (tempUpdateConfigIndex > -1) {
        tempUpdateConfig[tempUpdateConfigIndex] = { ...action.payload };
      }
      return {
        ...state,
        parkingConfig: tempUpdateConfig,
      };
    case actionTypes.SET_PARKING_REPORT:
      return {
        ...state,
        parkingReport: action.payload,
      };
    case actionTypes.SET_PARKING_SEARCH:
      return {
        ...state,
        parkingSearch: action.payload,
      };
    case actionTypes.SET_PARKING_SEARCH_LOADING:
      return {
        ...state,
        searchLoading: action.payload,
      };
    case actionTypes.SET_PARKING_CHECKOUT:
      return {
        ...state,
        parkingCheckout: action.payload,
      };
    case actionTypes.SET_USER_LOGOUT:
      return {
        parkingConfig: [],
        parkingReport: [],
        parkingSearch: [],
        parkingCheckout: {},
        searchLoading: false,
      };
    default:
      return state;
  }
};
