import React from 'react';

function SalesReportIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 520 520"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#ecebed"
        d="M407.5,445.5c0,20.44-16.56,37-37,37c-26.007,0-223.041,0-253,0c16.57,0,30-13.43,30-30 c0-17.758,0-388.463,0-400l30,20l20-20l20,20l20-20l20,20l20-20l20,20l20-20l20,20l20-20l20,20l30-20 C407.5,68.161,407.5,420.092,407.5,445.5z"
      />
      <path
        fill="#fd6814"
        d="M455,367.5c12.43,0,22.5,10.07,22.5,22.5s-10.07,22.5-22.5,22.5s-22.5-10.07-22.5-22.5 S442.57,367.5,455,367.5z"
      />
      <circle cx="72.5" cy="97.5" r="30" fill="#fd6814" />
      <rect width="80" height="60" x="302.5" y="292.5" fill="#73bcff" />
      <rect width="220" height="60" x="167.5" y="107.5" fill="#73bcff" />
      <path
        fill="#bcb7c6"
        d="M147.5,272.5v180c0,16.57-13.43,30-30,30c-8.28,0-15.78-3.36-21.21-8.79s-8.79-12.93-8.79-21.21 v-260h60V272.5z"
      />
      <circle cx="470" cy="40" r="7.5" />
      <circle cx="465" cy="285" r="7.5" />
      <circle cx="440" cy="135" r="7.5" />
      <circle cx="110" cy="50" r="7.5" />
      <circle cx="65" cy="370" r="7.5" />
      <circle cx="50" cy="165" r="7.5" />
      <path d="M370.5,487.5h-253c-2.761,0-5-2.238-5-5s2.239-5,5-5h253c17.645,0,32-14.355,32-32V61.843L380.273,76.66 c-1.984,1.322-4.625,1.06-6.309-0.625L357.5,59.571l-16.465,16.464c-1.951,1.952-5.119,1.952-7.07,0L317.5,59.571l-16.465,16.464 c-1.951,1.952-5.119,1.952-7.07,0L277.5,59.571l-16.465,16.464c-1.953,1.952-5.118,1.952-7.071,0L237.5,59.571l-16.464,16.464 c-1.953,1.952-5.118,1.952-7.071,0L197.5,59.571l-16.464,16.464c-1.686,1.686-4.327,1.945-6.309,0.625L152.5,61.843V192.5 c0,2.761-2.239,5-5,5s-5-2.239-5-5v-140c0-1.844,1.015-3.538,2.641-4.408c1.626-0.871,3.6-0.775,5.133,0.248l26.589,17.726 l17.102-17.102c1.953-1.952,5.118-1.952,7.071,0L217.5,65.429l16.464-16.464c1.953-1.952,5.118-1.952,7.071,0L257.5,65.429 l16.465-16.464c1.951-1.952,5.119-1.952,7.07,0L297.5,65.429l16.465-16.464c1.951-1.952,5.119-1.952,7.07,0L337.5,65.429 l16.465-16.464c1.951-1.952,5.119-1.952,7.07,0l17.102,17.102l26.59-17.726c1.536-1.024,3.508-1.119,5.133-0.248 c1.626,0.87,2.641,2.564,2.641,4.408v393C412.5,468.659,393.659,487.5,370.5,487.5z" />
      <path d="M117.5,487.5c-9.344,0-18.132-3.642-24.745-10.254S82.5,461.846,82.5,452.5v-260c0-2.761,2.239-5,5-5h60 c2.761,0,5,2.239,5,5v260C152.5,471.799,136.799,487.5,117.5,487.5z M92.5,197.5v255c0,6.674,2.602,12.951,7.325,17.674 c4.725,4.725,11.001,7.326,17.675,7.326c13.785,0,25-11.215,25-25v-255H92.5z" />
      <path d="M387.5,172.5h-220c-2.761,0-5-2.239-5-5v-60c0-2.761,2.239-5,5-5h220c2.762,0,5,2.239,5,5v60 C392.5,170.261,390.262,172.5,387.5,172.5z M172.5,162.5h210v-50h-210V162.5z" />
      <path d="M382.5,197.5h-210c-2.761,0-5-2.239-5-5s2.239-5,5-5h210c2.762,0,5,2.239,5,5S385.262,197.5,382.5,197.5z" />
      <path d="M382.5,217.5h-210c-2.761,0-5-2.239-5-5s2.239-5,5-5h210c2.762,0,5,2.239,5,5S385.262,217.5,382.5,217.5z" />
      <path d="M382.5,237.5h-210c-2.761,0-5-2.239-5-5s2.239-5,5-5h210c2.762,0,5,2.239,5,5S385.262,237.5,382.5,237.5z" />
      <path d="M382.5,257.5h-210c-2.761,0-5-2.238-5-5s2.239-5,5-5h210c2.762,0,5,2.238,5,5S385.262,257.5,382.5,257.5z" />
      <path d="M382.5,277.5h-210c-2.761,0-5-2.238-5-5s2.239-5,5-5h210c2.762,0,5,2.238,5,5S385.262,277.5,382.5,277.5z" />
      <path d="M382.5,377.5h-210c-2.761,0-5-2.238-5-5s2.239-5,5-5h210c2.762,0,5,2.238,5,5S385.262,377.5,382.5,377.5z" />
      <path d="M382.5,397.5h-210c-2.761,0-5-2.238-5-5s2.239-5,5-5h210c2.762,0,5,2.238,5,5S385.262,397.5,382.5,397.5z" />
      <path d="M282.5,297.5h-110c-2.761,0-5-2.238-5-5s2.239-5,5-5h110c2.762,0,5,2.238,5,5S285.262,297.5,282.5,297.5z" />
      <path d="M282.5,317.5h-110c-2.761,0-5-2.238-5-5s2.239-5,5-5h110c2.762,0,5,2.238,5,5S285.262,317.5,282.5,317.5z" />
      <path d="M282.5,337.5h-110c-2.761,0-5-2.238-5-5s2.239-5,5-5h110c2.762,0,5,2.238,5,5S285.262,337.5,282.5,337.5z" />
      <path d="M282.5,357.5h-110c-2.761,0-5-2.238-5-5s2.239-5,5-5h110c2.762,0,5,2.238,5,5S285.262,357.5,282.5,357.5z" />
      <path d="M382.5,357.5h-80c-2.762,0-5-2.238-5-5v-60c0-2.762,2.238-5,5-5h80c2.762,0,5,2.238,5,5v60 C387.5,355.262,385.262,357.5,382.5,357.5z M307.5,347.5h70v-50h-70V347.5z" />
      <path d="M455,417.5c-15.163,0-27.5-12.337-27.5-27.5s12.337-27.5,27.5-27.5s27.5,12.337,27.5,27.5S470.163,417.5,455,417.5z M455,372.5c-9.649,0-17.5,7.851-17.5,17.5s7.851,17.5,17.5,17.5s17.5-7.851,17.5-17.5S464.649,372.5,455,372.5z" />
      <path d="M72.5,132.5c-19.299,0-35-15.701-35-35s15.701-35,35-35s35,15.701,35,35S91.799,132.5,72.5,132.5z M72.5,72.5 c-13.785,0-25,11.215-25,25s11.215,25,25,25s25-11.215,25-25S86.285,72.5,72.5,72.5z" />
    </svg>
  );
}

export default SalesReportIcon;
