import {makeStyles} from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    height: 56,
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    borderLeft: '3px solid transparent',
    cursor: 'pointer',
    position: 'relative',
  },
  activeRoot: {
    // borderLeft: '5px solid #FEBF2C',
    backgroundColor: '#F53B57',
    color: '#FFFFFF',
  },
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  labelIcon: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 4,
    height: 32,
    width: 32,
    marginRight: theme.spacing(6),
  },
  diamondArrow: {
    width: 0,
    height: 0,
    border: '20px solid transparent',
    borderBottom: '28px solid #FEBF2C',
    position: 'relative',
    top: '-20px',
    '&:after': {
      content: "''",
      position: 'absolute',
      left: '-20px',
      top: '28px',
      width: 0,
      height: 0,
      border: '20px solid transparent',
      borderTop: '28px solid #FEBF2C',
    },
  },
}));
