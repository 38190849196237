import { createTheme } from "@mui/material/styles";


export const lightTheme = createTheme({
  palette: {
    primary: {main: '#212A39'},
  },
  spacing: 2,
  text: {
    primary: 'rgba(33, 42, 57, 0.87)',
    secondary: 'rgba(33, 42, 57, 0.5)',
  },
  // typography: {
  //   fontFamily: ['Inter'].join(','),
  // },
});

export const darkTheme = createTheme({
  palette: {
    primary: {main: 'rgba(255,255,255, 0.87)'},
  },
  spacing: 2,
  text: {
    primary: 'rgba(255, 255, 255, 0.87)',
    secondary: 'rgba(255, 255, 255, 0.5)',
  },
  // typography: {
  //   fontFamily: ['Inter'].join(','),
  // },
});
