import React from 'react';

function EmployeeIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <g data-name="08-Waiter">
        <path
          fill="#dce1ea"
          d="M60,38h3a3.995,3.995,0,0,1-4,4H45a4,4,0,0,1-4-4H60Z"
        />
        <rect width="4" height="3" x="54" y="18" fill="#dce1ea" />
        <path
          fill="#85d8b7"
          d="M60,28v2H52V28a2.015,2.015,0,0,1,2-2V21h4v5A2.006,2.006,0,0,1,60,28Z"
        />
        <path
          fill="#23cbab"
          d="M58,26V21H56v5a2.006,2.006,0,0,1,2,2v2h2V28A2.006,2.006,0,0,0,58,26Z"
        />
        <rect width="8" height="8" x="52" y="30" fill="#23cbab" />
        <path fill="#1dbc9d" d="M58,30h0a6,6,0,0,1-6,6h0v2h8V30Z" />
        <path fill="#ffcdbe" d="M59,42l-1,2-4,2H48V44c0-2,1-2,1-2Z" />
        <path fill="#ffbeaa" d="M50,45c0-2,1-2,1-2h7.5l.5-1H49s-1,0-1,2v2h2Z" />
        <path fill="#e9edf4" d="M54.31,50,55,59a4,4,0,0,1-8,0l.69-9Z" />
        <path
          fill="#cdd2e0"
          d="M49,59l.69-9h-2L47,59a3.953,3.953,0,0,0,5,3.859A3.99,3.99,0,0,1,49,59Z"
        />
        <polygon fill="#bec3d1" points="54 46 54.31 50 47.69 50 48 46 54 46" />
        <polygon fill="#afb4c7" points="48 46 47.69 50 49.69 50 50 46 48 46" />
        <path
          fill="#7f8596"
          d="M47.69,50,47,59a4,4,0,0,0,4,4H31V47l8-6-1.6-2.4L41,35V30.6l2.01.54a2.995,2.995,0,0,0,5.52,1.47L50,33s.36,1.92.83,5H41a4,4,0,0,0,4,4h4s-1,0-1,2v2ZM43,53V49H35v4Z"
        />
        <path
          fill="#23cbab"
          d="M49,26v5a3,3,0,0,1-3,3,2.993,2.993,0,0,1-2.99-2.86A.66.66,0,0,1,43,31V26Z"
        />
        <path
          fill="#1dbc9d"
          d="M47,26v4a3,3,0,0,1-3,3c-.081,0-.157-.017-.236-.023A2.991,2.991,0,0,0,49,31V26Z"
        />
        <rect width="8" height="4" x="35" y="49" fill="#e9edf4" />
        <path
          fill="#966857"
          d="M43,6v4a2.821,2.821,0,0,1-.73,2.05A3.421,3.421,0,0,1,40,13a9.291,9.291,0,0,1-3-6,3.7,3.7,0,0,0,2-3h2A1.959,1.959,0,0,1,43,6Z"
        />
        <path
          fill="#faa68e"
          d="M42.27,12.05A2.921,2.921,0,0,1,43,14a3,3,0,0,1-3,3V13A3.421,3.421,0,0,0,42.27,12.05Z"
        />
        <path
          fill="#afb4c7"
          d="M41,30.6V35l-3.6,3.6L37,38a4.38,4.38,0,0,0,1-3,4.38,4.38,0,0,0-1-3l-1.09.27.93-2.78Z"
        />
        <path
          fill="#ffcdbe"
          d="M34.14,26.43A9,9,0,0,1,22,18V9s1.66,0,3.9-.11c1.42-.06,3.09-.17,4.71-.34C33.28,8.26,35.83,7.78,37,7a9.291,9.291,0,0,0,3,6v5A9,9,0,0,1,34.14,26.43ZM37,14a2,2,0,1,0-2,2A2.006,2.006,0,0,0,37,14Zm-8,0a2,2,0,1,0-2,2A2.006,2.006,0,0,0,29,14Z"
        />
        <path
          fill="#ffbeaa"
          d="M27,18a2.006,2.006,0,0,0,2-2,1.975,1.975,0,0,0-.279-1,1.981,1.981,0,0,1-3.442,0A1.975,1.975,0,0,0,25,16,2.006,2.006,0,0,0,27,18Z"
        />
        <path
          fill="#ffbeaa"
          d="M35,18a2.006,2.006,0,0,0,2-2,1.975,1.975,0,0,0-.279-1,1.981,1.981,0,0,1-3.442,0A1.975,1.975,0,0,0,33,16,2.006,2.006,0,0,0,35,18Z"
        />
        <path
          fill="#ffbeaa"
          d="M37,7c-1.17.78-3.72,1.26-6.39,1.55-1.62.17-3.29.28-4.71.34C23.66,9,22,9,22,9v2s1.66,0,3.9-.11c1.42-.06,3.09-.17,4.71-.34C33.28,10.26,35.83,9.78,37,9a9.291,9.291,0,0,0,3,6V13A9.291,9.291,0,0,1,37,7Z"
        />
        <polygon
          fill="#afb4c7"
          points="37.4 38.6 39 41 31 47 34.23 37.31 37 38 37.4 38.6"
        />
        <path
          fill="#8d5c4d"
          d="M36,1a2.938,2.938,0,0,1,3,3,3.7,3.7,0,0,1-2,3s-4-2-4-6Z"
        />
        <path
          fill="#f62c24"
          d="M37,32a4.38,4.38,0,0,1,1,3,4.38,4.38,0,0,1-1,3l-2.77-.69L33,37V33l2.91-.73Z"
        />
        <path
          fill="#faa68e"
          d="M36.84,29.49l-.93,2.78L33,33H29l-2.91-.73-.93-2.78L27,29l.86-2.57a8.934,8.934,0,0,0,6.28,0L35,29Z"
        />
        <circle cx="35" cy="14" r="2" fill="#83dcfd" />
        <path
          fill="#7f5144"
          d="M33,1c0,4,4,6,4,6-1.17.78-3.72,1.26-6.39,1.55A12.148,12.148,0,0,1,28,1Z"
        />
        <polygon
          fill="#e9edf4"
          points="34.23 37.31 31 47 27.77 37.31 29 37 33 37 34.23 37.31"
        />
        <rect width="4" height="4" x="29" y="33" fill="#fc4538" />
        <path
          fill="#707788"
          d="M31,47V63H16V41c0-4-4-8-4-8l9-2.4V35l3.6,3.6L23,41Zm-4,6V49H19v4Z"
        />
        <polygon
          fill="#afb4c7"
          points="24.6 38.6 25 38 27.77 37.31 31 47 23 41 24.6 38.6"
        />
        <path
          fill="#6f463b"
          d="M28,1a12.148,12.148,0,0,0,2.61,7.55c-1.62.17-3.29.28-4.71.34A12.076,12.076,0,0,1,23,1Z"
        />
        <path
          fill="#f62c24"
          d="M29,33v4l-1.23.31L25,38a4.38,4.38,0,0,1-1-3,4.38,4.38,0,0,1,1-3l1.09.27Z"
        />
        <circle cx="27" cy="14" r="2" fill="#83dcfd" />
        <rect width="8" height="4" x="19" y="49" fill="#e9edf4" />
        <path
          fill="#afb4c7"
          d="M25.16,29.49l.93,2.78L25,32a4.38,4.38,0,0,0-1,3,4.38,4.38,0,0,0,1,3l-.4.6L21,35V30.6Z"
        />
        <path
          fill="#5e382e"
          d="M23,1a12.076,12.076,0,0,0,2.9,7.89C23.66,9,22,9,22,9c-3-1-3-8-3-8Z"
        />
        <path fill="#faa68e" d="M22,11v6a3,3,0,0,1,0-6Z" />
        <path fill="#e9edf4" d="M16,41V63H9c0-14,3-30,3-30S16,37,16,41Z" />
        <path
          fill="#cdd2e0"
          d="M12,33s-.136.727-.349,2A11.051,11.051,0,0,1,14,41V63h2V41C16,37,12,33,12,33Z"
        />
        <path d="M31,28A10.011,10.011,0,0,1,21,18V9h2v9a8,8,0,0,0,16,0V13h2v5A10.011,10.011,0,0,1,31,28Z" />
        <path d="M40,14l-.007-2A1.884,1.884,0,0,0,42,10V6a.915.915,0,0,0-1-1H39a1,1,0,0,1-1-1,1.882,1.882,0,0,0-2-2H20.037c.151,2.229.783,5.552,2.279,6.051l-.632,1.9C18.1,8.755,18,1.79,18,1a1,1,0,0,1,1-1H36a3.788,3.788,0,0,1,3.874,3H41a2.916,2.916,0,0,1,3,3v4A3.887,3.887,0,0,1,40,14Z" />
        <path d="M39.293,13.707a11.289,11.289,0,0,1-3.076-5.241C32.023,9.952,23.118,10,22,10V8c3.254,0,12.192-.33,14.445-1.832A1,1,0,0,1,38,7a8.325,8.325,0,0,0,2.707,5.293Z" />
        <path d="M37,8a1,1,0,0,1-.447-.105C36.367,7.8,32,5.565,32,1h2c0,2.558,2.031,4.2,2.977,4.822A2.41,2.41,0,0,0,38,4h2A4.7,4.7,0,0,1,37.447,7.9,1,1,0,0,1,37,8Z" />
        <path d="M29.833,9.18A13.153,13.153,0,0,1,27,1h2a11.112,11.112,0,0,0,2.386,6.919Z" />
        <path d="M25.293,9.707A12.921,12.921,0,0,1,22,1h2a11.111,11.111,0,0,0,2.709,7.3Z" />
        <path d="M31,23a4,4,0,0,1-4-4h2a2,2,0,0,0,4,0h2A4,4,0,0,1,31,23Z" />
        <path d="M22,18a4,4,0,0,1,0-8v2a2,2,0,0,0,0,4Z" />
        <path d="M40,18V16a2,2,0,0,0,2-2,1.915,1.915,0,0,0-.479-1.288l1.5-1.325A3.936,3.936,0,0,1,44,14,4,4,0,0,1,40,18Z" />
        <path d="M27,17a3,3,0,1,1,3-3A3,3,0,0,1,27,17Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,27,13Z" />
        <path d="M35,17a3,3,0,1,1,3-3A3,3,0,0,1,35,17Zm0-4a1,1,0,1,0,1,1A1,1,0,0,0,35,13Z" />
        <rect width="4" height="2" x="29" y="13" />
        <path d="M51,64H9a1,1,0,0,1-1-1c0-13.932,2.987-30.023,3.017-30.184a1,1,0,0,1,.725-.782l14.48-3.862.829-2.488,1.9.632-1,3a1,1,0,0,1-.691.65L12.872,33.8A193.01,193.01,0,0,0,10.005,62H51Z" />
        <path d="M49.841,38.151c-.308-2.021-.57-3.549-.712-4.347l-.856-.228.513-1.932,1.47.39a1,1,0,0,1,.727.782c0,.019.368,1.972.836,5.033Z" />
        <path d="M42.751,32.106l-8.009-2.14a1,1,0,0,1-.691-.65l-1-3,1.9-.632.829,2.489,7.49,2Z" />
        <path d="M51,64a5.006,5.006,0,0,1-5-5l1-13.077A1,1,0,0,1,48,45h6a1,1,0,0,1,1,.923l1,13A5.018,5.018,0,0,1,51,64ZM48.926,47,48,59.077A3,3,0,0,0,54,59l-.926-12Z" />
        <path d="M54.447,46.9l-.894-1.79,3.7-1.85L57.382,43H49.176A1.993,1.993,0,0,0,49,44v2H47V44c0-2.966,1.918-3,2-3H59a1,1,0,0,1,.9,1.447l-1,2a1,1,0,0,1-.448.448Z" />
        <path d="M59,43H45a5.006,5.006,0,0,1-5-5,1,1,0,0,1,1-1H63a1,1,0,0,1,1,1A5.006,5.006,0,0,1,59,43ZM42.171,39A3.006,3.006,0,0,0,45,41H59a3.006,3.006,0,0,0,2.829-2Z" />
        <rect width="8" height="2" x="47" y="49" />
        <path d="M33,38H29a1,1,0,0,1-1-1V33a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v4A1,1,0,0,1,33,38Zm-3-2h2V34H30Z" />
        <path d="M37,39a1.01,1.01,0,0,1-.243-.03l-4-1,.486-1.94,3.334.833a4.309,4.309,0,0,0,0-3.726l-3.333.833-.486-1.94,4-1a1,1,0,0,1,.95.263A5.311,5.311,0,0,1,39,35a5.311,5.311,0,0,1-1.293,3.707A1,1,0,0,1,37,39Z" />
        <path d="M25,39a1,1,0,0,1-.707-.293A5.311,5.311,0,0,1,23,35a5.311,5.311,0,0,1,1.293-3.707,1,1,0,0,1,.95-.263l4,1-.486,1.94-3.334-.833a4.309,4.309,0,0,0,0,3.726l3.333-.833.486,1.94-4,1A1.01,1.01,0,0,1,25,39Z" />
        <rect
          width="3.447"
          height="2"
          x="34.732"
          y="29.635"
          transform="rotate(-71.556 36.455 30.635)"
        />
        <rect
          width="2"
          height="3.447"
          x="24.545"
          y="28.912"
          transform="rotate(-18.38 25.59 30.636)"
        />
        <path d="M31,48a1,1,0,0,1-.949-.684l-3.23-9.69,1.9-.632L31,43.838l2.281-6.844,1.9.632-3.23,9.69A1,1,0,0,1,31,48Z" />
        <path d="M32,63H30V47a1,1,0,0,1,.4-.8l7.243-5.432-1.475-2.213,1.664-1.11,2,3A1,1,0,0,1,39.6,41.8L32,47.5Z" />
        <path d="M60,39H52a1,1,0,0,1-1-1V28a3.006,3.006,0,0,1,2-2.829V18a1,1,0,0,1,1-1h4a1,1,0,0,1,1,1v7.171A3.006,3.006,0,0,1,61,28V38A1,1,0,0,1,60,39Zm-7-2h6V28a1,1,0,0,0-1-1,1,1,0,0,1-1-1V19H55v7a1,1,0,0,1-1,1,1,1,0,0,0-1,1Z" />
        <rect width="4" height="2" x="54" y="20" />
        <rect width="8" height="2" x="52" y="29" />
        <path d="M46,35a4,4,0,0,1-4-4V26a1,1,0,0,1,1-1h6a1,1,0,0,1,1,1v5A4,4,0,0,1,46,35Zm-2-8v4a2,2,0,0,0,4,0V27Z" />
        <rect width="2" height="4" x="45" y="34" />
        <path d="M27,54H19a1,1,0,0,1-1-1V49a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v4A1,1,0,0,1,27,54Zm-7-2h6V50H20Z" />
        <path d="M43,54H35a1,1,0,0,1-1-1V49a1,1,0,0,1,1-1h8a1,1,0,0,1,1,1v4A1,1,0,0,1,43,54Zm-7-2h6V50H36Z" />
        <path d="M17,63H15V41c0-3.531-3.671-7.256-3.708-7.293l1.415-1.414C12.882,32.468,17,36.636,17,41Z" />
        <path d="M30.4,47.8l-8-6a1,1,0,0,1-.232-1.355l2-3,1.664,1.11-1.475,2.213L31.6,46.2Z" />
        <path d="M24.293,39.707l-4-4A1,1,0,0,1,20,35V30h2v4.586l3.707,3.707Z" />
        <path d="M37.707,39.707l-1.414-1.414L40,34.586V30h2v5a1,1,0,0,1-.293.707Z" />
      </g>
    </svg>
  );
}

export default EmployeeIcon;
