import React from "react";
import { useStyles } from "../styles";
import { useSelector } from "react-redux";
import logo from "../../../assets/header/employee-profile.jpg";
import { Chip, Stack } from "@mui/material";

function UserInfo() {
  const classes = useStyles();
  const employeeDetails = useSelector((state) => state.auth.loginData);

  return (
    <div className={classes.restInfoRoot}>
      <div className={classes.restInfoFlex}>
        <div className={classes.restInfoLogo}>
          <img src={logo} height={72} width={72} alt={""} />
        </div>
        <div className={classes.restInfoText}>
          <div className={classes.restTitle}>{employeeDetails?.username}</div>
          <div className={classes.restAddress}>
            <Stack direction="row" flexWrap={'wrap'}>
              <Chip
                size={"small"}
                style={{ fontSize: 9, textTransform: "uppercase", margin: 2 }}
                label={employeeDetails?.role}
                variant="outlined"
              />
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(UserInfo);
