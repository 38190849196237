import * as actionTypes from "../actionTypes";

let initialState = {
  amList: [],
  approvalList: [],
  storeList: [],
  partnerPaymentList: [],
  partnerPaymentLinkList: [],
  storeBrandMapping: [],
  storeBrandZSUrl: [],
  salesSummary: {},
  salesInsights: [],
  storeStatusCount: [],
  outOfStock: [],
  payoutSchedules: [],
  partnerPayoutSchedules: [],
  hourlySaleData: [],
};

export const storeDetailsReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_STORE_AREA_MANAGER_LIST:
      return {
        ...state,
        amList: action.payload,
      }
    case actionTypes.SET_STORE_APPROVAL_LIST:
      return {
        ...state,
        approvalList: action.payload,
      };
    case actionTypes.UPDATE_STORE_APPROVAL_LIST:
      let update_approval_list = [...state.approvalList];
      const update_approval_list_index = update_approval_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_approval_list_index > -1)
        update_approval_list[update_approval_list_index] = action.payload;
      return {
        ...state,
        approvalList: update_approval_list,
      };
    case actionTypes.SET_STORE_LIST:
      return {
        ...state,
        storeList: action.payload,
      };
    case actionTypes.UPDATE_STORE_LIST:
      let update_store_list = [...state.storeList];
      const update_store_list_index = update_store_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_store_list_index > -1)
        update_store_list[update_store_list_index] = action.payload;
      return {
        ...state,
        storeList: update_store_list,
      };
    case actionTypes.SET_PARTNER_PAYMENT_LIST:
      return {
        ...state,
        partnerPaymentList: action.payload,
      }
    case actionTypes.UPDATE_PARTNER_PAYMENT_LIST:
      let update_partner_payment_list = [...state.partnerPaymentList];
      const update_partner_payment_list_index = update_partner_payment_list.findIndex(
        (a) => a._id === action.payload._id
      );
      if (update_partner_payment_list_index > -1)
      update_partner_payment_list[update_partner_payment_list_index] = action.payload;
      return {
        ...state,
        partnerPaymentList: update_partner_payment_list
      }
    case actionTypes.SET_STORE_BRAND_MAPPING:
      return {
        ...state,
        storeBrandMapping: action.payload,
      }
    case actionTypes.UPDATE_STORE_BRAND_MAPPING:
      let update_store_brand_mapping = [...state.storeBrandMapping];
      const update_store_brand_mapping_index = update_store_brand_mapping.findIndex(
        (a) => a.brand_id === action.payload.brand_id
      );
      if (update_store_brand_mapping_index > -1)
      update_store_brand_mapping[update_store_brand_mapping_index] = action.payload;
      return {
        ...state,
        storeBrandMapping: update_store_brand_mapping,
      };
    case actionTypes.SET_STORE_BRAND_ZS_URL:
      return {
        ...state,
        storeBrandZSUrl: action.payload,
      }
    case actionTypes.UPDATE_STORE_BRAND_ZS_URL:
      let update_store_brand_zs_url = [...state.storeBrandZSUrl];
      const update_store_brand_zs_url_index = update_store_brand_zs_url.findIndex(
        (a) => a.brand_id === action.payload.brand_id
      );
      if (update_store_brand_zs_url_index > -1)
      update_store_brand_zs_url[update_store_brand_zs_url_index] = action.payload;
      return {
        ...state,
        storeBrandZSUrl: update_store_brand_zs_url,
      };
    case actionTypes.SET_PARTNER_PAYMENT_LINK_LIST:
      return {
        ...state,
        partnerPaymentLinkList: action.payload,
      }
    case actionTypes.SET_EMPLOYEE_SALES_SUMMARY:
      return {
        ...state,
        salesSummary: action.payload,
      }
    case actionTypes.SET_EMPLOYEE_SALES_INSIGHTS:
      return {
        ...state,
        salesInsights: action.payload,
      }
    case actionTypes.SET_STORE_STATUS_COUNT:
      return {
        ...state,
        storeStatusCount: action.payload,
      }
    case actionTypes.SET_RISTA_ITEMS_OUT_OF_STOCK:
      return {
        ...state,
        outOfStock: action.payload,
      }
    case actionTypes.SET_EMPLOYEE_PAYOUT_SCHEDULES:
      return {
        ...state,
        payoutSchedules: action.payload,
      }
    case actionTypes.SET_PARTNER_PAYOUT_SCHEDULES:
      return {
        ...state,
        partnerPayoutSchedules: action.payload
      }
    case actionTypes.SET_EMPLOYEE_HOURLY_SALES:
      return {
        ...state,
        hourlySaleData: action.payload,
      }
    case actionTypes.SET_USER_LOGOUT:
      return {
        amList: [],
        approvalList: [],
        storeList: [],
        partnerPaymentList: [],
        partnerPaymentLinkList: [],
        storeBrandMapping: [],
        salesSummary: {},
        salesInsights: [],
        storeStatusCount: [],
        outOfStock: [],
        payoutSchedules: [],
        partnerPayoutSchedules: [],
        hourlySaleData: []
      };
    default:
      return state;
  }
};
