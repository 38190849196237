import moment from "moment";

export const API_URL = `${process.env.REACT_APP_API_URL}/urban-square`;

/**
 * Employee
 */
export const ROLES = ["admin", "parking", "account", "tenant", "senior-engineer", "junior-engineer", "operational-manager", "floor-supervisor", "accounts-manager", "business-development", "general-manager", "senior-accountant", "junior-accountant"];

export const getDateFormat = (from, to) => {
  let temp = "";
  if (moment(from).isSame(moment(to), "date")) {
    if (moment().isSame(moment(from), "date")) {
      temp = `Today ${moment(to).format("MMM DD, YYYY")}`;
    } else if (moment().subtract(1, "day").isSame(moment(from), "date")) {
      temp = `Yesterday ${moment(to).format("MMM DD, YYYY")}`;
    } else {
      temp = `${moment(from).format("MMM DD, YYYY")}`;
    }
  } else if (
    moment(from).isSame(moment().subtract(7, "days"), "date") &&
    moment(to).isSame(moment(), "date")
  ) {
    temp = `Last week ${moment(from).format("MMM DD")} - ${moment(to).format(
      "MMM DD, YYYY"
    )}`;
  } else {
    temp = `${moment(from).format("MMM DD")} - ${moment(to).format(
      "MMM DD, YYYY"
    )}`;
  }
  return temp;
};
