import * as actionTypes from "../actionTypes";
import {SET_MANAGED_DOCUMENTS} from "../actionTypes";

let initialState = {
  loading: false,
  reloadAPI: false,
  checkLists: [],
  checkListResponses: [],
  myCheckLists: [],
  approvalCheckLists: [],
  invoices: [],
  events:[],
  managedDocuments: []
};

export const miscReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MISC_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actionTypes.SET_RELOAD_API:
      return {
        ...state,
        reloadAPI: action.payload,
      };
    case actionTypes.SET_CHECKLISTS:
      return {
        ...state,
        checkLists: action.payload,
      };
    case actionTypes.CREATE_CHECKLISTS:
      return {
        ...state,
        checkLists: [...state.checkLists, action.payload],
      };
    case actionTypes.SET_MY_CHECKLISTS:
      return {
        ...state,
        myCheckLists: action.payload,
      };
    case actionTypes.SET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
      };
    case actionTypes.SET_EVENTS:
      return {
        ...state,
        events: action.payload,
      };
    case actionTypes.SET_CHECKLIST_RESPONSES:
      return {
        ...state,
        checkListResponses: action.payload,
      };
    case actionTypes.SET_APPROVAL_RESPONSES:
      return {
        ...state,
        approvalCheckLists: action.payload,
      };
    case actionTypes.SET_MANAGED_DOCUMENTS:
      return {
        ...state,
        managedDocuments: action.payload,
      };
    case actionTypes.ADD_EVENT:
      return {
        ...state,
        events: [...state.events, action.payload],
      };
    case actionTypes.UPDATE_INVOICE:
      return {
        ...state,
        invoices: state.invoices.map(invoice => {
          return (invoice._id===action.payload._id)?action.payload:invoice
        }),
      };
    default:
      return state;
  }
};
