import { toast } from "react-toastify";
import { setMiscLoading } from ".";
import { user_http } from "../../https/user_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";

export const fetchParkingConfig = () => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/parking-config`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARKING_CONFIG,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};
export const updateParkingConfig = (data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/parking-config`, data)
      .then((res) => {
        dispatch(setMiscLoading(false));
        dispatch({
          type: actionTypes.UPDATE_PARKING_CONFIG,
          payload: res.data,
        });
      })
      .catch(() => {
        toast.error("Unable to update ticket !");
        dispatch(setMiscLoading(false));
      });
  };
};
export const fetchParkingReport = (from, to) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/parking-details?from=${from}&to=${to}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARKING_REPORT,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};
export const updateCheckIn = (formData) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/parking-details`, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(() => {
        dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to check in !");
        dispatch(setMiscLoading(false));
      });
  };
};
export const fetchParkingSearch = (number) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.SET_PARKING_SEARCH_LOADING, payload: true });
    dispatch({
      type: actionTypes.SET_PARKING_CHECKOUT,
      payload: {},
    });
    user_http
      .get(`${API_URL}/parking-details/search?number=${number}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARKING_SEARCH,
          payload: res.data,
        });
        dispatch({
          type: actionTypes.SET_PARKING_SEARCH_LOADING,
          payload: false,
        });
      })
      .catch(() => {
        dispatch({
          type: actionTypes.SET_PARKING_SEARCH_LOADING,
          payload: false,
        });
      });
  };
};
export const resetParkingCheckout = () => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_PARKING_SEARCH_LOADING,
      payload: false,
    });
    dispatch({
      type: actionTypes.SET_PARKING_SEARCH,
      payload: [],
    });
    dispatch({
      type: actionTypes.SET_PARKING_CHECKOUT,
      payload: {},
    });
  };
};
export const fetchParkingCheckOut = (number) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/parking-details/charges?number=${number}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_PARKING_CHECKOUT,
          payload: res.data,
        });
        dispatch(setMiscLoading(false));
      })
      .catch(() => {
        dispatch(setMiscLoading(false));
      });
  };
};
export const updateAmountCheckOut = (id, data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .put(`${API_URL}/parking-details/${id}`, data)
      .then(() => {
        dispatch(setMiscLoading(false));
        dispatch(resetParkingCheckout());
      })
      .catch(() => {
        toast.error("Unable to check out !");
        dispatch(setMiscLoading(false));
      });
  };
};
