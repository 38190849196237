export const SET_STORE_APPROVAL_LIST = "SET_STORE_APPROVAL_LIST";
export const UPDATE_STORE_APPROVAL_LIST = "UPDATE_STORE_APPROVAL_LIST";
export const SET_STORE_LIST = "SET_STORE_LIST";
export const UPDATE_STORE_LIST = "UPDATE_STORE_LIST";

export const SET_STORE_AREA_MANAGER_LIST = "SET_STORE_AREA_MANAGER_LIST";

export const SET_STORE_BRAND_MAPPING = "SET_STORE_BRAND_MAPPING";
export const UPDATE_STORE_BRAND_MAPPING = "UPDATE_STORE_BRAND_MAPPING";

export const SET_PARTNER_PAYMENT_LIST = "SET_PARTNER_PAYMENT_LIST";
export const UPDATE_PARTNER_PAYMENT_LIST = "UPDATE_PARTNER_PAYMENT_LIST";
export const SET_PARTNER_PAYMENT_LINK_LIST = "SET_PARTNER_PAYMENT_LINK_LIST";

export const SET_EMPLOYEE_SALES_SUMMARY = "SET_EMPLOYEE_SALES_SUMMARY";
export const SET_EMPLOYEE_SALES_INSIGHTS = "SET_EMPLOYEE_SALES_INSIGHTS";

export const SET_EMPLOYEE_PAYOUT_SCHEDULES = "SET_EMPLOYEE_PAYOUT_SCHEDULES";

export const SET_PARTNER_PAYOUT_SCHEDULES = "SET_PARTNER_PAYOUT_SCHEDULES";

export const SET_STORE_STATUS_COUNT = "SET_STORE_STATUS_COUNT";

export const SET_RISTA_ITEMS_OUT_OF_STOCK = "SET_RISTA_ITEMS_OUT_OF_STOCK";

export const SET_STORE_BRAND_ZS_URL = "SET_STORE_BRAND_ZS_URL";
export const UPDATE_STORE_BRAND_ZS_URL= "UPDATE_STORE_BRAND_ZS_URL";

export const SET_EMPLOYEE_HOURLY_SALES = "SET_EMPLOYEE_HOURLY_SALES";
