import * as actionTypes from "../actionTypes";

let initialState = {
  userList: [],
  userAttendance: [],
  salaryDetails: []
};

export const userReducers = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_LIST:
      return {
        ...state,
        userList: action.payload,
      };
    case actionTypes.UPDATE_USER_LIST:
      let updateUserList = [...state.userList];
      const updateUserListIndex = updateUserList.findIndex(a => a._id === action.payload._id);
      if (updateUserListIndex > -1) {
        updateUserList[updateUserListIndex] = action.payload;
      } else {
        updateUserList.push(action.payload)
      }
      return {
        ...state,
        userList: updateUserList
      }
    case actionTypes.FETCH_USER_ATTENDANCE:
      return {
        ...state,
        userAttendance: action.payload
      };
    case actionTypes.UPDATE_USER_ATTENDANCE:
      let updateUserAttendance = [...state.userAttendance];
      const updateUserAttendanceIndex = updateUserAttendance.findIndex(
        (a) => a.date === action.payload.date
      );
      if (updateUserAttendanceIndex > -1) {
        updateUserAttendance[updateUserAttendanceIndex] = action.payload;
      }
      return {
        ...state,
        userAttendance: updateUserAttendance,
      };
    case actionTypes.INIT_USER_SALARY:
      return {
        ...state,
        salaryDetails: action.payload,
      };
    case actionTypes.UPDATE_USER_SALARY:
      let updateSalary = [...state.salaryDetails];
      const updateSalaryIndex = updateSalary.findIndex(
        (a) => a._id === action.payload._id
      );
      if (updateSalaryIndex > -1) {
        updateSalary[updateSalaryIndex] = action.payload;
      }
      return {
        ...state,
        salaryDetails: updateSalary,
      };
    case actionTypes.SET_USER_LOGOUT:
      return {
        ...state,
        userList: [],
        userAttendance: [],
        salaryDetails: []
      };
    default:
      return state;
  }
};
