import { toast } from "react-toastify";
import { setMiscLoading } from ".";
import { user_http } from "../../https/user_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import moment from "moment";
import {SET_ANALYTICS_RENT_OVERVIEW} from "../actionTypes";

export const fetchSalesOverview = (date) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/sale?date=${moment(date).format('MMM-YYYY')}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ANALYTICS_SALES_OVERVIEW,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to fetch sales overview!");
        dispatch(setMiscLoading(false));
      });
  };
};
export const fetchRentOverview = (date) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .get(`${API_URL}/sale/rentDetails?month=${moment(date).format('MMM-YYYY')}`)
      .then(async (res) => {
        await dispatch({
          type: actionTypes.SET_ANALYTICS_RENT_OVERVIEW,
          payload: res.data,
        });
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to fetch sales overview!");
        dispatch(setMiscLoading(false));
      });
  };
};

export const uploadSalesOverview = (file, date) => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        let formData = new FormData();
        formData.append("file", file);
        formData.append("date", moment(date).format('MMM-YYYY'));
        user_http
            .post(`${API_URL}/sale/import`, formData, {
                "Content-Type": "multipart/form-data",
            })
            .then(async (res) => {
                toast.success(`
          Added: ${res.data?.successCount},
          Error: ${res.data?.errorCount}
        `);
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error("Error in uploading data !");
                dispatch(setMiscLoading(false));
            });
    };
};
