import { user_http } from "../../https/user_http";
import { API_URL } from "../../utils/constants";
import * as actionTypes from "../actionTypes";
import { toast } from "react-toastify";
import {SET_MANAGED_DOCUMENTS} from "../actionTypes";

export const setMiscLoading = (data) => ({
  type: actionTypes.SET_MISC_LOADING,
  payload: data,
});

export const setReloadAPI = (data) => ({
  type: actionTypes.SET_RELOAD_API,
  payload: data,
});

export const getCheckLists = () => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .get(`${API_URL}/checklist/`)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.SET_CHECKLISTS,
                    payload: res.data,
                })
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error("Unable to get Checklists!");
                dispatch(setMiscLoading(false));
            });
    };
};

export const getMyCheckLists = () => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .get(`${API_URL}/checklist/my`)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.SET_MY_CHECKLISTS,
                    payload: res.data,
                })
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error("Unable to get my Checklists!");
                dispatch(setMiscLoading(false));
            });
    };
};

export const createCheckList = (data, cb) => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .post(`${API_URL}/checklist/`, data)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.CREATE_CHECKLISTS,
                    payload: res.data,
                })
                await dispatch(setMiscLoading(false));
                cb();
            })
            .catch(() => {
                toast.error("Unable to create Checklists!");
                dispatch(setMiscLoading(false));
            });
    };
};
export const saveCheckListResponse = (data, id, cb) => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .post(`${API_URL}/checklist/saveResponse/${id}`, data)
            .then(async (res) => {
                await dispatch(setMiscLoading(false));
                cb();
            })
            .catch(() => {
                toast.error("Unable to create Checklists!");
                dispatch(setMiscLoading(false));
            });
    };
};

export const generateInvoiceAPI = (data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/invoice`, data)
      .then(async (res) => {
        const link = document.createElement("a");
        link.href = res.data.link;
        link.setAttribute(
          "download",
          `${data.invoice_no}(${new Date().toDateString()}).pdf`
        );
        document.body.appendChild(link);
        link.click();
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to generate invoice!");
        dispatch(setMiscLoading(false));
      });
  };
};
export const generateRentInvoiceAPI = (data) => {
  return (dispatch) => {
    dispatch(setMiscLoading(true));
    user_http
      .post(`${API_URL}/invoice/rent`, data)
      .then(async (res) => {
        const link = document.createElement("a");
        link.href = res.data.link;
        link.setAttribute(
          "download",
          `${data.invoice_no}(${new Date().toDateString()}).pdf`
        );
        document.body.appendChild(link);
        link.click();
        await dispatch(setMiscLoading(false));
      })
      .catch(() => {
        toast.error("Unable to generate invoice!");
        dispatch(setMiscLoading(false));
      });
  };
};

export const fetchInvoices = () => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .get(`${API_URL}/invoice`)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.SET_INVOICES,
                    payload: res.data,
                });
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error('Unable to get invoices list !')
                dispatch(setMiscLoading(false));
            });
    };
};
export const fetchManagedDocuments = () => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .get(`${API_URL}/document-management`)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.SET_MANAGED_DOCUMENTS,
                    payload: res.data,
                });
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error('Unable to get managed documents !')
                dispatch(setMiscLoading(false));
            });
    };
};

export const ackInvoice = (data, cb) => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .put(`${API_URL}/invoice/acknowledge`,data)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.UPDATE_INVOICE,
                    payload: res.data[0],
                });
                await dispatch(setMiscLoading(false));
                cb();
            })
            .catch(() => {
                toast.error('Unable to get invoices list !')
                dispatch(setMiscLoading(false));
            });
    };
};


export const fetchEvents = (date) => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .post(`${API_URL}/events/date`, {date})
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.SET_EVENTS,
                    payload: res.data,
                });
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error('Unable to get events list !')
                dispatch(setMiscLoading(false));
            });
    };
};
export const saveEvent = (data,date, cb) => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .post(`${API_URL}/events`, data)
            .then(async (res) => {
                await dispatch(fetchEvents(date));
                await dispatch(setMiscLoading(false));
                cb();
            })
            .catch(() => {
                toast.error('Unable to get events list !')
                dispatch(setMiscLoading(false));
            });
    };
};



export const fetchChecklistResponses = (checklistId) => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .get(`${API_URL}/checklist/getResponse/${checklistId}`)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.SET_CHECKLIST_RESPONSES,
                    payload: res.data,
                });
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error('Unable to get events list !')
                dispatch(setMiscLoading(false));
            });
    };
};

export const fetchApprovalResponses = () => {
    return (dispatch) => {
        dispatch(setMiscLoading(true));
        user_http
            .get(`${API_URL}/checklist/approvalResponse/`)
            .then(async (res) => {
                await dispatch({
                    type: actionTypes.SET_APPROVAL_RESPONSES,
                    payload: res.data,
                });
                await dispatch(setMiscLoading(false));
            })
            .catch(() => {
                toast.error('Unable to get events list !')
                dispatch(setMiscLoading(false));
            });
    };
};