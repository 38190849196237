import React from "react";
import { Route, Redirect } from "react-router";
import { useSelector } from "react-redux";

function LoginPageHoc({ component: Component, ...rest }) {
  const userId = useSelector((state) => state.auth.loginData?._id);

  return (
    <Route
      {...rest}
      render={(prop) =>
        !userId ? (
          <Component {...prop} />
        ) : (
          <Redirect to={'/app/dashboard'} />
        )
      }
    />
  );
}

export default LoginPageHoc;
