import React from 'react';

function ManageItemsIcon({size}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={size ? size : '32'}
      height={size ? size : '32'}
    >
      <path
        fill="#00efd1"
        d="M496.04,106.68V385.02a40,40,0,0,1-40,40H325.91a40.127,40.127,0,0,0-13.67,2.41l-42.57,15.48a39.956,39.956,0,0,1-13.68,2.41l.01-.44V86.98a39.858,39.858,0,0,0,13.67-2.41l42.57-15.48a40.127,40.127,0,0,1,13.67-2.41H456.04A40,40,0,0,1,496.04,106.68Z"
      />
      <path
        fill="#fedb41"
        d="M165.61,214.81,184,155.95H85.89l15.85,54.96,31.24,108.35.02-.06ZM256,87.54V444.88l-.01.44a39.8,39.8,0,0,1-13.66-2.41l-42.57-15.48a40.127,40.127,0,0,0-13.67-2.41H55.96a40,40,0,0,1-40-40V106.68a40,40,0,0,1,40-40H186.09a40.127,40.127,0,0,1,13.67,2.41l42.57,15.48A39.858,39.858,0,0,0,256,86.98Z"
      />
      <path
        fill="#d4e1f4"
        d="M184,155.95l-18.39,58.86c-3.95-3.28-6.86-6.86-15.01-6.86-15.01,0-15.01,12.13-30.02,12.13-10.32,0-13.06-5.59-18.79-9.18l-.05.01L85.89,155.95Z"
      />
      <path
        fill="#00efd1"
        d="M133,319.2l-.02.06L101.74,210.91l.05-.01c5.73,3.59,8.47,9.18,18.79,9.18,15.01,0,15.01-12.13,30.02-12.13,8.15,0,11.06,3.58,15.01,6.86Z"
      />
      <path
        fill="#083863"
        d="M456.04,59H325.91a47.879,47.879,0,0,0-16.4,2.731l-42.58,15.4a32.089,32.089,0,0,1-21.862-.044l-42.58-15.343A47.871,47.871,0,0,0,186.09,59H55.96A47.747,47.747,0,0,0,8,106.68V385.02A48,48,0,0,0,55.96,433H186.09a32.024,32.024,0,0,1,10.935,1.939l42.562,15.481a47.972,47.972,0,0,0,32.817.007l42.561-15.486A32.052,32.052,0,0,1,325.91,433H456.04A48,48,0,0,0,504,385.02V106.68A47.747,47.747,0,0,0,456.04,59ZM202.484,419.9A48,48,0,0,0,186.09,417H55.96A31.985,31.985,0,0,1,24,385.02V106.68A31.73,31.73,0,0,1,55.96,75H186.09a31.877,31.877,0,0,1,10.935,1.768l42.561,15.4A47.39,47.39,0,0,0,248,94.306V436.3c-.99-.256-1.972-.559-2.936-.911ZM488,385.02A31.985,31.985,0,0,1,456.04,417H325.91a48.026,48.026,0,0,0-16.4,2.9l-42.574,15.486c-.963.351-1.943.654-2.932.909V94.306a47.477,47.477,0,0,0,8.4-2.218l42.561-15.316A31.88,31.88,0,0,1,325.91,75H456.04A31.73,31.73,0,0,1,488,106.68Z"
      />
      <path
        fill="#083863"
        d="M318.29,160h51.17a8,8,0,1,0,0-16H318.29a8,8,0,0,0,0,16Z"
      />
      <path
        fill="#083863"
        d="M439.46,207H318.29a8,8,0,1,0,0,16H439.46a8,8,0,1,0,0-16Z"
      />
      <path
        fill="#083863"
        d="M439.46,270H318.29a8,8,0,1,0,0,16H439.46a8,8,0,1,0,0-16Z"
      />
      <path
        fill="#083863"
        d="M439.46,332H318.29a8,8,0,1,0,0,16H439.46a8,8,0,1,0,0-16Z"
      />
      <path
        fill="#083863"
        d="M184,148H85.89A7.965,7.965,0,0,0,78.2,158.179L125,320.457V342H109a8,8,0,0,0,0,16h49a8,8,0,0,0,0-16H141V320.419l50.636-162.083A7.961,7.961,0,0,0,184,148ZM133.288,291.449l-18.419-63.881a30.983,30.983,0,0,0,5.711.512c10.333,0,15.942-4.533,20.038-7.843,3.421-2.764,5.305-4.287,9.982-4.287a8.849,8.849,0,0,1,5.78,1.581Zm27.947-89.435a26.259,26.259,0,0,0-10.635-2.051c-10.333,0-15.943,4.526-20.038,7.836-3.421,2.764-5.305,4.284-9.982,4.284-4.572,0-6.18-1.356-9.383-4.055-.785-.662-1.635-1.347-2.564-2.061L96.522,164h76.6Z"
      />
    </svg>
  );
}

export default ManageItemsIcon;
